
import { defineComponent, onMounted, reactive } from "vue";
import ApiService from "@/core/services/ApiService";
import { ErrorMessage, Field, Form } from "vee-validate";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import * as jsPDF from "jspdf";
import moment from "moment";
import { Modal } from "bootstrap";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    Loading,
    pdfMake,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      // loading overlay setting
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      imageFotoBank: "",
      // end loading overlay setting

      Data: [],
      detail: [],
    };
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH_FINANCE);
    if (this.$route.params._id != "multi-invoice") {
      this.getIdInvoices();
    } else {
      this.getDataCache();
    }
  },

  methods: {
    getIdInvoices() {
      this.isLoading = true;

      const toast = useToast();
      const $route = useRoute();

      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/invoice/" + this.$route.params._id
      )
        .then((data) => {
          this.Data = data.data;

          switch (data.data.vaBank) {
            case 'NTB SYARIAH':
              this.imageFotoBank = "/media/bank/bank_ntbs.png";
              break;
            case 'BNI':
              this.imageFotoBank = "/media/bank/bank_bni.png";
              break;
            case 'BRI':
              this.imageFotoBank = "/media/bank/bank_bri.png";
              break;
            case 'BSI-SBI':
              this.imageFotoBank = "/media/bank/bank_bsi.png";
              break;
            case 'BANKJATIM':
              this.imageFotoBank = "/media/bank/bank_jatim_syariah.png";
              break;
            case 'MANDIRI':
              this.imageFotoBank = "/media/bank/bank_mandiri.png";
              break;
            case 'PERMATA':
              this.imageFotoBank = "/media/bank/bank_permata.png";
          }

          this.detail = data.data.detail;

          this.isLoading = false;
        })
        .catch((_e) => {
          this.isLoading = false;
          // toast.error(e.res.data.detail)
        });
    },

    getDataCache() {
      this.isLoading = true;

      const array = JSON.parse(localStorage.getItem("listDetailInv")!);

      this.Data = array;
      this.detail = array.detail;
      this.isLoading = false;
    },

    printpreview() {
      const modal = new Modal(document.getElementById("kt_modal_print"));
      modal.show();
    },

    printpdf() {
      var printContents = document.getElementById("capturepdf")!.innerHTML;

      document.body.innerHTML = printContents;
      window.print();
      location.reload();
    },

    // exportPDF() {
    //   var vm = this
    //   var columns = [
    //     {title: "Title", dataKey: "title"},
    //     {title: "Description", dataKey: "description"}
    //   ];
    //   var doc = new jsPDF('p', 'pt');
    //   doc.text('To Do List', 40, 40);
    //   doc.autoTable(columns, vm.Data, {
    //     margin: {top: 60},
    //   });
    //   doc.save('Tagihan.pdf');
    // },

    // printDocument() {

    //   var pdf = new jsPDF('p', 'pt', 'letter');

    //   var ele = document.getElementById("kt_account_profile_details");
    //   var margins = {
    //     top: 20,
    //     bottom: 60,
    //     left: 60,
    //     width: 400
    //   };
    //   var cb = function (_dispose) {
    //     pdf.save('Tagihan.pdf');
    //   }
    //   var opt =  { 'width': margins.width };
    //   pdf.fromHTML(ele,margins.left, margins.top, opt, cb, margins)
    // },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    formatDate(value) {
      return moment(value).format("L");
    },

    // printDownload() {
    //   let w = window.open()
    //     w.document.write(this.$refs.DownloadComp.$el.innerHTML)
    //     w.document.close()
    //     w.setTimeout(function () {
    //     w.print()
    //   }, 1000)
    // },
    cekNull(value) {
      if (value) return value;
      else return "-";
    },
  },
});

// function html2pdf(_element: HTMLElement|null) {
//   throw new Error("Function not implemented.");
// }
